import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { httpErrorMessages } from '../constants/httpErrorMessages'
import { useIdentifications } from '../hooks/useIdentifications'
import { ErrorContext, HttpError } from '../interfaces/api'

import { AlertDialog } from './AlertDialog'
import { datadogLogs } from '@datadog/browser-logs'

export const ErrorDialog: FC<{
  onClick: () => void
  httpError: HttpError | null
  errorContext: ErrorContext | null
}> = ({ onClick, httpError, errorContext }) => {

  const [isOpen, setIsOpen] = useState(false)
  const { removeValidationCode } = useIdentifications()

  const { formatMessage } = useIntl()

  const closeDialog = () => {
    removeValidationCode()
    onClick()
    setIsOpen(false)
  }

  useEffect(() => {
    if (httpError) {
      setIsOpen(true)
    }
  }, [httpError])

  if (httpError) {
    const errorLogMessage = errorContext?.name ?? 'error dialog shown'
    const errorInstance = new Error(errorLogMessage)
    const finalContext = errorContext ? { ...errorContext, httpError: httpError } : { httpError: httpError }
    datadogLogs.logger.error(errorLogMessage, finalContext, errorInstance)
  }

  const errorObject = httpErrorMessages(httpError?.error?.ErrorCode, httpError?.error?.code)
  return (
    <AlertDialog
      buttonYes={formatMessage(errorObject.button)}
      onYesClick={closeDialog}
      open={isOpen}
      text={formatMessage(errorObject.text)}
      title={formatMessage(errorObject.title)}
    />
  )
}
