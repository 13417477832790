import MuiButton, { ButtonProps } from '@mui/material/Button'
import { FC, PropsWithChildren, ReactElement } from 'react'

interface Props extends ButtonProps {
  fullWidth?: boolean
  icon?: ReactElement
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  fullWidth,
  icon,
  onClick,
  variant = 'contained',
  ...rest
}) => {

  return (
    <MuiButton
      sx={{
        justifyContent: fullWidth ? 'left' : undefined,
        width: fullWidth ? '100%' : undefined,
      }}
      {...rest}
      onClick={onClick}
      variant={variant}
      {...(icon ? { endIcon: icon } : {})}
    >
      {children}
    </MuiButton>
  )
}
