import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

interface fileBase64InFile {
  Content: string
  FileName?: string
  Metadata: string
}

export function downloadFile(file: fileBase64InFile) {
  try {
    const linkSource = `${file.Metadata}${file?.Content}`
    const downloadLink = document.createElement('a')
    const fileName = file?.FileName ? file.FileName : 'file'
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  } catch (error) {
    const errorInstance = new Error('error downloading file')
    datadogLogs.logger.error(
      'Error occurred during save the file (downloadFile):',
      { error: error },
      errorInstance
    )
     datadogRum.addError(error, {
      error: error,
      name: 'Error occurred during save the file (downloadFile):',
    })
  }
}