import { FC } from 'react'
import { SignableDoc } from '../interfaces/api'
import { useTheme } from '@mui/material'
import { colors } from '../theming/colors'
import { DocusealForm } from '@docuseal/react'
import { useLocalization } from '../hooks/useLocalization'

interface Props {
    doc: SignableDoc
    submitFunction: (documentId: string, signedDate: string) => Promise<void>
}

export const DocuSealWidget: FC<Props> = ({
    doc,
    submitFunction
}) => {

    const theme = useTheme();
    const { locale } = useLocalization()
    return (
        <DocusealForm
            src={`https://docuseal.eu/s/${doc.DocumentId}`}
            email={doc.Email}
            role={doc.Role}
            onComplete={(data) => submitFunction(doc.DocumentId, data?.completed_at)}
            withTitle={false}
            allowToResubmit={false}
            sendCopyEmail={false}
            language={locale}
            withDownloadButton={true}
            withSendCopyButton={false}
            customCss={`
                        #submit_form_button { 
                            background-color: ${theme.palette.primary.main}; 
                            border: 1px solid ${theme.palette.primary.main};
                            color: ${colors.WHITE}
                        }
                         #expand_form_button {
                            background-color: ${theme.palette.primary.main}; 
                            border: 1px solid ${theme.palette.primary.main};
                            color: ${colors.WHITE}
                        }
                       .btn-outline:hover {
                                background-color: ${theme.palette.primary.main};
                                border: 1px solid ${theme.palette.primary.main};
                        } 
                        .base-button {
                           background-color: ${theme.palette.primary.main}; 
                           border: 1px solid ${theme.palette.primary.main};
                           color: ${colors.WHITE}  
                        }

                        .base-button:hover {
                                background-color: ${colors.WHITE};
                                border: 1px solid ${theme.palette.primary.main};
                                color: ${theme.palette.primary.main};
                        } 
                        .bg-base-content {
                             background-color: ${theme.palette.primary.main}; 
                        }
                        .bg-red-100 {
                            background-color: ${theme.palette.primary.light};
                        }
                        .border-red-500 {
                            border-color: ${theme.palette.primary.main};
                        }
                        .outline-2 {
                            outline-width: 3px;
                        }
                        .border-red-100 {
                            border-color: ${theme.palette.primary.main};
                        }
                        canvas {
                            width: -webkit-fill-available;
                        }
                         .flex.justify-between {
                            @media screen and (max-width: 515px) {
                                    flex-direction: column-reverse;
                                }
                        }
                        #form_container { 
                            font-family: 'Gabriel Sans';
                        }
                    `}
        />
    )
}
