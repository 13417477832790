import {  keepPreviousData, useQuery } from '@tanstack/react-query'

import { getFiles } from '../api/getFiles'
import {
  FilesResponse, HttpError,
} from '../interfaces/api'
import { useHttpErrors } from './useHttpError'
import { useIdentifications } from './useIdentifications'
import { useEffect } from 'react'

export const useFiles = () => {

  const { UUID, validationCode, userIntent } = useIdentifications()
  const {updateHttpError} = useHttpErrors()

 const onError = (error:unknown) => {
      const errorContext = {
        error: error,
        uuid: UUID ?? '',
        name: 'error on useFiles - get files',
      }
      updateHttpError(error as HttpError, errorContext)
    }

    const fetchFiles = async () => {
      const fetchedFiles = await filesQuery.refetch()
      if (fetchedFiles.status === 'success') {
        return
      }
      if(fetchedFiles.status === 'error') {
        onError(fetchedFiles.error)
        return
      }
    }

  useEffect(() => {
    if (Boolean(UUID && validationCode && userIntent === 'Download')) {
      fetchFiles()
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UUID, validationCode, userIntent])

  const filesQuery = useQuery<FilesResponse>({
    queryKey: ['files'],
    queryFn: () => getFiles(UUID, validationCode),
    enabled: false,
    placeholderData: keepPreviousData,
    retry:3,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  return {
    files: filesQuery.data ?? null,
    isFetchingFiles: filesQuery.isFetching,
    hasFiles: Boolean(filesQuery?.data && filesQuery?.data?.Attachments?.length > 0)
  }
}
