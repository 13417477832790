import { http, HttpResponse } from 'msw'
import { filesMock } from './responses/files.mock'
import {brandingMock} from './responses/branding.mock'
import { signDocumentsMock, signedDocumentsMock } from './responses/signdocuments.mock'

const API = process.env.REACT_APP_PUBLIC_API_URL

export const handlers = [
  http.get(`${API}/customerportal/branding`, (x) => {
    return HttpResponse.json(brandingMock)}),
  http.get(`${API}/documentportal/v1/documents`, (x) => {
    return HttpResponse.json(filesMock)}),
     http.get(`${API}/documentportal/v1/signables`, (x) => {
    return HttpResponse.json(signDocumentsMock)}),
     http.post(`${API}/documentportal/v1/signables`, (x) => {
    return HttpResponse.json(signedDocumentsMock)}),
]