import { FilesResponse } from '../interfaces/api'
import { fetchAPI } from '../utils/fetchAPI'


export const getFiles = (UUID?: string, validationCode?:string) => {
  
const API = process.env.REACT_APP_PUBLIC_API_URL

 const headers = {
    'Content-Type': 'application/json',
    ...(UUID ? {'Uuid': UUID}: {}),
    ...(validationCode ? {'Security-Code': validationCode}: {})
  }

  return fetchAPI<FilesResponse>(`${API}/documentportal/v1/documents`, {
    headers,
    method: 'GET',
  })
}
