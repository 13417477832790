import { Box, Portal } from '@mui/material'
import { keyframes } from '@mui/material'
import { CSSProperties, FC, useRef } from 'react'

import { colors } from '../theming/colors'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface LoaderProps {
  backgroundColor?: string
  color?: string
  fullscreen?: boolean
  size?: number | Record<'xs' | 'md', number>
}

export const Loader: FC<LoaderProps> = ({
  backgroundColor,
  color,
  fullscreen = false,
  size,
}) => {
  const mountTime = useRef(Date.now())
  const mountDelay = -(mountTime.current % 1000)

  const spinnerSize = fullscreen
    ? size ?? { md: 120, xs: 70 }
    : size ?? { md: 60, xs: 20 }
  const dotSize = {
    md: (typeof spinnerSize === 'object' ? spinnerSize.md : spinnerSize) / 10,
    xs: (typeof spinnerSize === 'object' ? spinnerSize.xs : spinnerSize) / 10,
  }
  return (
    <Portal disablePortal={!fullscreen}>
      <Box
        className="loading-spinner"
        sx={{
          display: 'flex',
          height: spinnerSize,
          position: 'relative',
          width: spinnerSize,
          ...(fullscreen
            ? {
              alignItems: 'center',
              bottom: 0,
              left: 0,
              margin: 'auto',
              position: 'absolute',
              right: 0,
              top: 0,
            }
            : undefined),
        }}
      >
        <Box
          style={{ '--spinner-delay': `${mountDelay}ms` } as CSSProperties}
          sx={{
            backgroundColor: backgroundColor ?? 'transparent',
            position: 'absolute',
            width: '100%',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              height: spinnerSize,
              position: 'relative',
              width: spinnerSize,
            }}
          >
            {new Array(6).fill(0).map((_, el) => (
              <Box
                key={el}
                className="loading-spinner-dot"
                style={{
                  animationDelay: `calc(var(--spinner-delay) + ${(el + 1) * 0.1
                    }s)`,
                  // opacity: Math.max(0.5, Math.sin((Math.PI / 6) * el)),
                }}
                sx={{
                  '&:after': {
                    backgroundColor: color || colors.BLACK,
                    borderRadius: '50%',
                    content: "''",
                    display: 'block',
                    height: dotSize,
                    margin: '0 auto',
                    width: dotSize,
                  },
                  animation: `${rotate} 1.2s cubic-bezier(0.5, 0.3, 0.4, 0.8) 0s infinite normal none running`,
                  animationDelay: 'var(--spinner-delay)',
                  height: '90%',
                  left: '5%',
                  position: 'absolute',
                  top: '5%',
                  width: '90%',
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Portal>
  )
}
