export const signDocumentsMock = {
  Signables: [{ Role: 'FirstParty',
  DocumentId: '54Nr6PyUyTDxLL',
  Email:'simoneassumpcao@openclaims.com',
  //SignedDate: '2022-03-23T14:52:55.012Z',
}],
  ErrorCode: 0
  }


  export const signedDocumentsMock = {
  Signables: [{ Role: 'FirstParty',
  DocumentId: 'HqxL75jzC34qvh',
  Email:'simoneassumpcao@openclaims.com',
  SignedDate: '2022-03-23T14:52:55.012Z'}],
  ErrorCode: 0,
  }