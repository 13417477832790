import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

type FetchFn = <T = unknown>(
  input: RequestInfo,
  init?: RequestInit | undefined
) => Promise<T>

export const fetchAPI: FetchFn = async (input, init) => {
  const res = await fetch(input, init)

  if (res.ok && res.status === 200) {
    return await res.json()
  }
  // we try/catch in case we have a malformed JSON ( for example)
  try {
    const error = await res.json()
    throw error
  } catch (error) {
     const errorInstance = new Error('error fetch API - catch')
    datadogLogs.logger.error('error fetch API - catch', {
      endpoint: input,
      error: error,
    }, errorInstance )

    datadogRum.addError(error, {
      endpoint: input,
      error: error,
      name: 'error fetch API - catch',
    })
    throw error
  }
}