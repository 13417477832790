import { SignDocsResponse } from '../interfaces/api'
import { fetchAPI } from '../utils/fetchAPI'


export const postSignedDocs = ({documentId, signedDate}:{documentId:string, signedDate:string}, UUID?: string, validationCode?:string  )  => {
  
const API = process.env.REACT_APP_PUBLIC_API_URL

 const headers = {
    'Content-Type': 'application/json',
    ...(UUID ? {'Uuid': UUID}: {}),
    ...(validationCode ? {'Security-Code': validationCode}: {})
  }

  return fetchAPI<SignDocsResponse>(`${API}/documentportal/v1/signables`, {
    body: JSON.stringify({Signables: [{DocumentId: documentId, SignedDate:signedDate}]}),
    headers,
    method: 'POST',
  })
}
