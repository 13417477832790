import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'

import React from 'react';
import { useBranding } from './hooks/useBranding';
import { useFiles } from './hooks/useFiles';
import { Loader } from './components/Loader';
import { Navbar } from './components/Navbar';
import { useHttpErrors } from './hooks/useHttpError';
import { ErrorDialog } from './components/ErrorDialog';
import { getPageComponent } from './pages/getPageComponent';
import { useIdentifications } from './hooks/useIdentifications';
import { useSignDocs } from './hooks/useSignDocs';
import { ErrorPage } from './pages/ErrorPage';
import { useCookieConsent } from './hooks/useCookieConsent';
import { Cookie } from './components/Cookie';

function App() {

  const { isLoading: brandingLoading, availableLanguages } = useBranding()
  const cookieConsentHook = useCookieConsent()
  const filesHoook = useFiles()
  const { files, isFetchingFiles } = filesHoook

  const signDocsHook = useSignDocs()
  const { signableDocs, isFetchingSignableDocs } = signDocsHook
  const { httpError, errorContext, clearHttpError } = useHttpErrors()
  const { validUrl } = useIdentifications()

  const PageComponent = getPageComponent(files, signableDocs)


  if (brandingLoading) {
    return (<Loader fullscreen />)
  }

  if (!validUrl) {
    return (<React.Fragment>
      <Navbar availableLanguages={availableLanguages} />
      <ErrorPage />
    </React.Fragment>)
  }

  return (
    <React.Fragment>
      <Navbar availableLanguages={availableLanguages} />
      <PageComponent
        {...filesHoook}
        {...signDocsHook}
        isFetching={isFetchingFiles || isFetchingSignableDocs}
      />
      <ErrorDialog httpError={httpError} errorContext={errorContext} onClick={clearHttpError} />
      <Cookie {...cookieConsentHook} />
    </React.Fragment>
  );
}

export default App;
