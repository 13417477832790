const noop = () => null

const LocalStorage =
  typeof window === 'undefined'
    ? { clear: noop, getItem: noop, removeItem: noop, setItem: noop }
    : localStorage

const SessionStorage =
  typeof window === 'undefined'
    ? { clear: noop, getItem: noop, removeItem: noop, setItem: noop }
    : sessionStorage

  const generateLocalStorageFn = <T>(key: string) => ({
  clear() {
    LocalStorage.removeItem(key)
  },
  get(): T | null {
    const data = LocalStorage.getItem(key)
    return data ? JSON.parse(data) : null
  },
  save(value: T) {
    LocalStorage.setItem(key, JSON.stringify(value))
  },
})

const generateSessionStorageFn = <T>(key: string) => ({
  clear() {
    SessionStorage.removeItem(key)
  },
  get(): T | null {
    const data = SessionStorage.getItem(key)
    return data ? JSON.parse(data) : null
  },
  save(value: T) {
    SessionStorage.setItem(key, JSON.stringify(value))
  },
})

export const browserStorage = {
  validationCodeStorage: generateSessionStorageFn<string>('validationCode'),
   CookieConsentStorage: generateLocalStorageFn<{
    datadog: boolean
  }>('consCode'),
} as const
