import { getHttpErrrorCode } from "../translations/dynamic/errorCodes"

const errorsMap: Record<
  number,
  Record<number, { 
  button: string
  text:string 
  title:string }>
> = {
  400: {
    3: {
      button: 'missingSessionHeaderButton',
      text: 'missingSessionHeaderText',
      title: 'missingSessionHeaderTitle',
    },
  },
  401: {
       1: {
      button: 'incorrectCredentialsButton',
      text: 'incorrectCredentialsText',
      title: 'incorrectCredentialsTitle',
    },
    },
}

const getCurrentError = (
  errorCode: number | undefined | 'default',
  errorStatus: number | undefined
) => {

  const genericError = {
    button: 'genericErrorButton',
    text: 'genericErrorText',
    title: 'genericErrorTitle',
  }

  if(errorCode === 'default') {
    return genericError
  }

  if (errorStatus) {
    const error = errorsMap[errorStatus]
    if (error && errorCode) {
      const errorTexts = error[errorCode]
      if (errorTexts) {
        return errorTexts
      }
    }
  }

  return genericError
}

export const httpErrorMessages = (
  errorCode: number | undefined | 'default',
  errorStatus: number | undefined
) => ({
  button: getHttpErrrorCode(getCurrentError(errorCode, errorStatus).button),
  text: getHttpErrrorCode(getCurrentError(errorCode, errorStatus).text),
  title: getHttpErrrorCode(getCurrentError(errorCode, errorStatus).title),
})
