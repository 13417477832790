import {  keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'

import {
  SignDocsResponse, HttpError,
} from '../interfaces/api'
import { useHttpErrors } from './useHttpError'
import { useIdentifications } from './useIdentifications'
import { getSignDocs } from '../api/getSignDocs'
import { postSignedDocs } from '../api/postSignedDocs'
import { useEffect } from 'react'

export const useSignDocs = () => {

  const { UUID, validationCode, userIntent } = useIdentifications()
  const {updateHttpError} = useHttpErrors()

   const onError = (error:unknown) => {
      const errorContext = {
        error: error,
        uuid: UUID ?? '',
        name: 'error on useSignDocs - get docs to sign',
      }
      updateHttpError(error as HttpError, errorContext)
    }

     const fetchDocs = async () => {
      const fetchedFiles = await signDocsQuery.refetch()
      if (fetchedFiles.status === 'success') {
        return
      }
      if(fetchedFiles.status === 'error') {
        onError(fetchedFiles.error)
        return
      }
    }

      useEffect(() => {
    if (Boolean(UUID && validationCode && userIntent === 'Sign'))
      fetchDocs()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ UUID, validationCode, userIntent])

  const signDocsQuery = useQuery<SignDocsResponse>({
    queryKey: ['signableDocs'],
    queryFn: () => getSignDocs(UUID, validationCode),
    enabled: false,
    placeholderData: keepPreviousData,
    retry:3,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

   const signedDocMutation = useMutation({
     mutationKey: ['signedDocMutation'],

     mutationFn: async ({documentId, signedDate}:{documentId:string, signedDate:string}) => {
       const response = await postSignedDocs(
        {documentId,signedDate },
        UUID,
        validationCode
       )
       return response
     },

     onError: (error: unknown) => {
       const errorContext = {
         error: error,
         lp: '',
         name: 'error on useSignDocs - post docs signed',
         uuid: UUID ?? '',
       }
       updateHttpError(error as HttpError, errorContext)
     }
   })

  return {
    signableDocs: signDocsQuery.data ?? null,
    isFetchingSignableDocs: signDocsQuery.isFetching,
    hasSignableDocs: Boolean(signDocsQuery?.data && signDocsQuery?.data?.Signables?.length > 0),
    confirmSignedDocument: async(documentId:string, signedDate:string) => signedDocMutation.mutate({documentId,signedDate})
  }
}
