import { Box, Stack } from '@mui/material'
import TextField from '@mui/material/TextField';

import { Title } from '../components/Title'
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { useIdentifications } from '../hooks/useIdentifications';
import { colors } from '../theming/colors'
import { Loader } from '../components/Loader';
import { PageProperties } from './getPageComponent';



export const Home: FC<PageProperties> = ({ isFetching }) => {

    useEffect(() => {
        function onEnterPress(event: KeyboardEvent) {
            const key = event.code
            const enterKey = 'Enter'
            const numpadEnter = 'NumpadEnter'
            if (key === enterKey || key === numpadEnter) {
                event.preventDefault()
            }
        }

        document.addEventListener('keypress', onEnterPress);
        return () => document.removeEventListener('keypress', onEnterPress);

    }, []);

    const { updateValidationCode } = useIdentifications()

    const [validationCode, setValidationCode] = useState('');
    const [error, setError] = useState(false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidationCode(event.target.value);
    };

    const validCharacters = new RegExp("^[0-9]{6}$")

    const handleSubmit = () => {
        updateValidationCode(validationCode)
    }
    // we submit the form when user finishes typing the validation code
    useEffect(() => {
        if (validCharacters.test(validationCode)) {
            handleSubmit()
        }
        if (!validCharacters.test(validationCode) && validationCode.length >= 6) {
            setError(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationCode])

    const endAdornment = useMemo(() => {
        if (isFetching && validCharacters.test(validationCode)) {
            return (
                <Loader size={25} />
            )
        }
        return null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, validationCode])

    return (
        <Box
            component={'div'}
            sx={{ height: '100%', width: '100%', padding: '0 40px' }}

        >
            <Stack sx={{
                alignItems: 'center',
                color: 'primary.main',
                height: '100%',
                justifyContent: 'center',
                '& form': {
                    '& .MuiFormControl-root': {
                        width: 'auto',
                    },
                    color: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '10px',
                    width: { md: '30%', xs: '100%' }
                },
            }}>
                <Title as="h3">
                    <FormattedMessage
                        defaultMessage="Fill in the security code you received in your email"
                        description="fill validation code text"
                        id="code_validation.title"
                    />
                </Title>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <Box display="flex" flexDirection="column">
                        <TextField
                            id="validation-code"
                            variant="standard"
                            value={validationCode}
                            onChange={handleChange}
                            InputProps={{ endAdornment }}
                        />
                        {error && (
                            <Box className="error" color={colors.RED} mt={1}>
                                <FormattedMessage
                                    defaultMessage="Please, make sure the code is the same as you received in your mail"
                                    description="invalid characters"
                                    id="code_validation.invalid-characters"
                                />
                            </Box>
                        )}
                    </Box>

                </Box>
            </Stack>
        </Box>
    )
}
