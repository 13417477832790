import { PropsWithChildren } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IdentificationsProvider } from './hooks/useIdentifications'
import { LocalizationProvider } from './hooks/useLocalization'
import { Theme } from './theming/Theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HttpErrorProvider } from './hooks/useHttpError'

const queryClient = new QueryClient()

export const Providers = ({ children }: PropsWithChildren<unknown>) => (
    <QueryClientProvider client={queryClient}>
        <LocalizationProvider>
            <IdentificationsProvider>
                <HttpErrorProvider>
                    <HelmetProvider>
                        <Theme>{children}</Theme>
                    </HelmetProvider>
                </HttpErrorProvider>
            </IdentificationsProvider>
        </LocalizationProvider>
    </QueryClientProvider>
)
