import { Box, BoxProps } from '@mui/material'
import React, { Children } from 'react'

import { clampBuilder } from '../theming/scaling'

type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
type TitleProps = React.PropsWithChildren<{ as?: Level }> & BoxProps

const headingSizeMap = {
  h1: {
    maxFontSize: 5.0,
    maxLineHeight: 5.5,
    minFontSize: 3.0,
    minLineHeight: 3.2,
  },
  h2: {
    maxFontSize: 3.5,
    maxLineHeight: 3.6,
    minFontSize: 2.2,
    minLineHeight: 2.4,
  },
  h3: {
    maxFontSize: 2.6,
    maxLineHeight: 2.7,
    minFontSize: 1.2,
    minLineHeight: 1.3,
  },
  h4: {
    maxFontSize: 1.4,
    maxLineHeight: 1.5,
    minFontSize: 1.1,
    minLineHeight: 1.2,
  },
  h5: {
    maxFontSize: 1,
    maxLineHeight: 1.1,
    minFontSize: 0.8,
    minLineHeight: 0.9,
  },
}

// Returns defaults
const getHeadingSize = (as: Level) =>
  headingSizeMap[as] ?? {
    maxFontSize: 2.4,
    maxLineHeight: 2.5,
    minFontSize: 0.6,
    minLineHeight: 0.7,
  }

export function Title({ as = 'h1', children, ...props }: TitleProps) {
  const { maxFontSize, maxLineHeight, minFontSize, minLineHeight } =
    getHeadingSize(as)

  return (
    <Box
      component={as}
      fontSize={clampBuilder(1200, 1800, minFontSize, maxFontSize)}
      letterSpacing="0.025rem"
      textAlign='center'
      lineHeight={clampBuilder(1200, 1800, minLineHeight, maxLineHeight)}
      {...props}
    >
      {Children.toArray(children)}
    </Box>
  )
}
