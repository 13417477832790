

import { useFiles } from '../hooks/useFiles'
import { Home } from './Home'
import { DownloadFiles } from './DownladFiles'
import { SignDocuments } from './SignDocuments'
import { useSignDocs } from '../hooks/useSignDocs'
import { FilesResponse, SignDocsResponse } from '../interfaces/api'

export interface PageProperties
    extends ReturnType<typeof useFiles>,
    ReturnType<typeof useSignDocs> {
    isFetching: boolean
}

export const getPageComponent = (files: FilesResponse | null, signableDocs: SignDocsResponse | null) => {
    if (files) {
        return DownloadFiles
    }
    if (signableDocs) {
        return SignDocuments
    }
    return Home
}
