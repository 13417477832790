import { defineMessages } from 'react-intl'

import { createLabelFn } from './createLabelFn'


const httpErrorcodeLabels = defineMessages({
  genericErrorButton: {
    defaultMessage: 'OK',
    id: 'http_error.generic-error-confirm-button',
  },
  genericErrorText: {
    defaultMessage: 'Please try again.',
    id: 'http_error.generic-error-text',
  },
  genericErrorTitle: {
    defaultMessage: 'Something is wrong',
    id: 'http_error.generic-error-title',
  },
  incorrectCredentialsButton: {
    defaultMessage: 'Ok',
    id: 'http_error.incorrect-credentials-confirm-button',
  },
  incorrectCredentialsText: {
    defaultMessage: 'Something went wrong',
    id: 'http_error.incorrect-credentials-text',
  },
  incorrectCredentialsTitle: {
    defaultMessage: 'Something went wrong',
    id: 'http_error.incorrect-credentials-title',
  },
  missingSessionHeaderButton: {
    defaultMessage: 'Ok',
    id: 'http_error.missing-session-header-confirm-button',
  },
  missingSessionHeaderText: {
    defaultMessage: 'Something went wrong. ',
    id: 'http_error.missing-session-header-text',
  },
  missingSessionHeaderTitle: {
    defaultMessage: 'Something went wrong',
    id: 'http_error.missing-session-header-title',
  },
})

export const getHttpErrrorCode = createLabelFn(httpErrorcodeLabels, {
  defaultMessage: 'Unknown error label',
  id: 'UNKNOWN.item',
})
