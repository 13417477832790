import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import DownloadIcon from '@mui/icons-material/Download';
import { FC } from 'react'

import { FileOC } from '../interfaces/api'
import { colors } from '../theming/colors'

interface FileItemProps {
  file: FileOC
  onClick: () => void
}

export const FileItem: FC<FileItemProps> = ({
  file,
  onClick,
}) => {

  return (
    <ListItem key={file.FileName} sx={{ backgroundColor: colors.WHITE }}>
      <ListItemText primary={file.FileName} />
      <ListItemSecondaryAction
        sx={{ '& svg': { path: { fill: colors.BLACK } } }}
      >
        <IconButton edge="end" onClick={onClick}>
          <DownloadIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
