import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

//import { browserStorage } from '../utils/browserStorage'

interface Identifiers {
  brandingID?: string
  UUID?: string
  userIntent?: string
  validationCode?: string
}
interface IdentifersContextData {
  brandingID?: string
  userIntent?: string
  validUrl: boolean
  UUID?: string
  validationCode?: string
  updateValidationCode: (newCode: string) => void
  removeValidationCode: () => void
}

const noop = () => null

export const IdentificationsContext = createContext<IdentifersContextData>({
  brandingID: undefined,
  UUID: undefined,
  validationCode: undefined,
  validUrl: false,
  updateValidationCode: noop,
  removeValidationCode: noop
})

export const IdentificationsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const url = new URL(document.location.toString())
  const params = url.searchParams

  const userIntent = params.get('UserIntent') ?? undefined
  const brandingID = params.get('BrandingID') ?? undefined
  const UUID = params.get('UUID') ?? undefined
  const validationCode = undefined // browserStorage.validationCodeStorage.get() ?? undefined
  const validUrl = Boolean(UUID && userIntent)
  const [ids, setIds] = useState<Identifiers>({
    brandingID: brandingID,
    userIntent: userIntent,
    UUID: UUID,
    validationCode: validationCode
  })

  return (
    <IdentificationsContext.Provider
      value={{
        brandingID: ids.brandingID,
        UUID: ids.UUID,
        userIntent: ids.userIntent,
        validationCode: ids.validationCode,
        validUrl,
        updateValidationCode: (newCode: string) => {
          setIds((currentIds) => ({ ...currentIds, validationCode: newCode }))
          //browserStorage.validationCodeStorage.save(newCode)
        },
        removeValidationCode: () => {
          setIds((currentIds) => ({ ...currentIds, validationCode: undefined }))
          //browserStorage.validationCodeStorage.clear()
        },
      }}
    >
      {children}
    </IdentificationsContext.Provider>
  )
}

export const useIdentifications = () => {
  return useContext(IdentificationsContext)
}
