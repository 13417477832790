import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Box, Stack } from '@mui/material'

import { Title } from '../components/Title'
import { FormattedMessage } from 'react-intl'

import { FC, Fragment } from 'react';
import { Paragraph } from '../components/Paragraph';
import dayjs from 'dayjs';
import { DocuSealWidget } from '../components/DocuSealWidget';
import { PageProperties } from './getPageComponent';


export const SignDocuments: FC<PageProperties> = ({ signableDocs, hasSignableDocs, confirmSignedDocument }) => {

    dayjs.extend(localizedFormat)

    const availableDocs = signableDocs ? signableDocs.Signables : null

    const Docs = availableDocs?.map(doc => {
        if (doc.SignedDate) {
            return (
                <Fragment key={doc.DocumentId}>
                    <Title as="h3">
                        <FormattedMessage
                            defaultMessage="This document has already been signed"
                            description="sign documents text"
                            id="sign_documents.documents-already-signed.title"
                        />
                    </Title>
                    <Paragraph>
                        <FormattedMessage
                            defaultMessage="Document was signed {ts}"
                            id="sign_documents.documents-already-signed.text"
                            values={{ ts: dayjs(doc.SignedDate).format('LLLL') }}

                        />
                    </Paragraph>
                </Fragment>
            )
        }
        return (
            <Fragment key={doc.DocumentId}>
                <Box component="div">
                    <Title as="h3" >
                        <FormattedMessage
                            defaultMessage="Document(s) to sign"
                            description="sign documents text"
                            id="sign_documents.title"
                        />
                    </Title>
                </Box>
                <Box
                    component="div"
                    sx={{ width: '100%', maxHeight: { lg: '70%', xl: '80%', xs: '80%', } }}
                >
                    <DocuSealWidget doc={doc} submitFunction={confirmSignedDocument} />
                </Box>
            </Fragment>
        )
    })

    if (!hasSignableDocs) {
        return <Box
            component={'div'}
            sx={{ height: '100%' }}

        >
            <Stack spacing={3} sx={{
                alignItems: 'center',
                color: 'primary.main',
                height: '100%',
                justifyContent: 'center',
                '& form': {
                    '& .MuiFormControl-root': {
                        width: 'auto',
                    },
                    color: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '10px',
                    width: { lg: '50%', md: '30%', xs: '100%' }
                },
            }}>
                <Title as="h3">
                    <FormattedMessage
                        defaultMessage="There are no documents to sign"
                        description="sign documents text"
                        id="sign_documents.no-documents-title"
                    />
                </Title>
            </Stack>
        </Box>
    }
    return (
        <Box
            component={'div'}
            sx={{ height: '100%', padding: '0 40px' }}

        >
            <Stack spacing={3} sx={{
                alignItems: 'center',
                color: 'primary.main',
                height: '100%',
                justifyContent: 'center',
                '& form': {
                    '& .MuiFormControl-root': {
                        width: 'auto',
                    },
                    color: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '10px',
                    width: { lg: '50%', md: '30%', xs: '100%' }
                },
            }}>
                {Docs}
            </Stack>
        </Box>
    )
}
