import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getBranding } from '../api/getBranding'
import { BrandingResponse } from '../interfaces/api'
import { colors } from '../theming/colors'
import { useIdentifications } from './useIdentifications'
import { datadogLogs } from '@datadog/browser-logs'
import { useEffect } from 'react'

export const useBranding = () => {
  const defaultBranding: Partial<BrandingResponse> = {
     AvailableLanguages: [
      { Language: 'en', PreferredLanguage: true },
      { Language: 'nl' },
    ],
    BackgroundURL: '',
    ColorPrimaryHEX: colors.BLACK,
    ColorSecondaryHEX: colors.GREY_LIGHT,
    Favicon: '',
    LogoSize: 'M',
    LogoURL: '',
  }

  const onError = (error:unknown) => {
      const errorContext = {
        brandingID: brandingID ?? '',
        error: error,
        name: 'error on useBranding - get branding',
      }
      const errorInstance = new Error(errorContext?.name)
      datadogLogs.logger.error(errorContext?.name, errorContext, errorInstance)
    }

  const { brandingID} = useIdentifications()
  const brandingQuery = useQuery<BrandingResponse>({
    queryKey: ['branding', brandingID],
    queryFn: () => getBranding(brandingID),
    enabled: Boolean(brandingID),
       placeholderData: keepPreviousData,
    retry:3,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

    useEffect(() => {
    if (brandingQuery.isError) {
      onError(brandingQuery.error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandingQuery.isError])

  return {
    availableLanguages: brandingQuery?.data?.AvailableLanguages ? brandingQuery?.data?.AvailableLanguages.map(
        (lang) => {
          if (typeof lang === 'string') {
            return lang
          }
          return lang.Language
        }
      ) : ['en','nl'],
    branding: brandingQuery.data ?? defaultBranding,
    isLoading: brandingQuery.isLoading,
    isFetching: brandingQuery.isFetching,
    usesFormalLanguage: brandingQuery.data?.FormalInformal === 'Formal',
    brandingID: brandingID
  }
}
